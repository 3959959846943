import { getLocalTimezoneOffsetFromUTC } from '../../../../Common/Helpers/GeneralHelpers'
import { TSortOrder } from '../../../../Common/Logic/Types'

// Get the order direction for the table header
const getOrderDirection = (
  column: string,
  order: TSortOrder,
  orderBy: string
) => {
  return orderBy === column ? order : 'asc'
}

const convertValueToDaysDown = (timeDownInDays: number) => {
  if (timeDownInDays < 10) {
    return '<10 days'
  } else if (timeDownInDays <= 30) {
    return '10-30 days'
  } else if (timeDownInDays <= 180) {
    return '30-180 days'
  } else {
    return '>180 days'
  }
}

const convertTechnologyType = (technologyType: string) => {
  switch (technologyType) {
    case 'Plc':
      return 'PLC'
    case 'Pcb':
      return 'PCB'
    case 'ReclaimedWater':
      return 'RW'
    default:
      return technologyType
  }
}

const getDownsSystemsTableHeadCells = (isUTCBooleanString?: string) => {
  const isUTC = isUTCBooleanString === 'true'
  const headCells = [
    {
      align: 'center',
      id: 'snoozeIcon',
      name: '',
      sortable: false,
      tooltip: '',
      width: '1px', // Minimum width. Setting to 0 causes cell overlap
    },
    {
      align: 'left',
      id: 'lastSeenTimestamp',
      name: `Last Seen (${
        isUTC
          ? 'UTC - UTC+00:00'
          : 'Local - UTC' + getLocalTimezoneOffsetFromUTC()
      })`,
      sortable: true,
      tooltip: 'Last Seen Sharing Data',
      width: '200px',
    },
    {
      align: 'left',
      id: 'systemId',
      name: 'System ID',
      sortable: true,
      width: '120px',
    },
    {
      align: 'left',
      id: 'technologyType',
      name: 'Tech.',
      sortable: true,
      tooltip: 'Technology',
      width: '40px',
    },
    {
      align: 'left',
      id: 'divisionName',
      name: 'Producer',
      sortable: true,
      width: '320px',
    },
    {
      align: 'left',
      id: 'location',
      name: 'Location',
      sortable: true,
      width: '200px',
    },
    {
      align: 'left',
      id: 'daysDown',
      name: 'Time Down',
      sortable: true,
      width: '120px',
    },
    {
      align: 'left',
      id: 'action',
      name: '',
      sortable: false,
      width: '50px',
    },
  ]
  return headCells
}

const downSystemsTableHelper = {
  convertTechnologyType,
  convertValueToDaysDown,
  getOrderDirection,
  getDownsSystemsTableHeadCells,
}

export default downSystemsTableHelper
