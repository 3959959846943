import { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { REFRESH_INTERVAL_DOWNSYSTEMS } from '../../../Hardware/Sonar/Constants/SonarConstants'
import downSystemsHelper from './DownSystemsHelper'
import { ISonarDownSystem, TSortOrder } from '../../../Common/Logic/Types'
import { atomSonarFilter } from '../../../Common/atoms'

export const useDownSystems = () => {
  const [lastRefreshed, setLastRefreshed] = useState<string>(
    downSystemsHelper.formatTime(new Date()) // Initial state set with formatted time
  )
  const [order, setOrder] = useState<TSortOrder>('desc')
  const [orderBy, setOrderBy] = useState<string>('lastSeenTimestamp')
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [tableCount, setTableCount] = useState<number>(0)
  const [downSystemsData, setDownSystemsData] = useState<any[]>([])
  const [snoozeSavedFlag, setSnoozeSavedFlag] = useState<boolean>(false)
  const [downSystemsDownloadData, setDownSystemsDownloadData] = useState<
    ISonarDownSystem[] | []
  >([])

  const sonarFilter = useRecoilValue(atomSonarFilter)
  const [csvFilename, setCsvFilename] = useState<string>(
    downSystemsHelper.defineDownSystemsDataFileName(sonarFilter)
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const retrieveDownSystemsData = useCallback(
    async abortController => {
      const { count, results } = await downSystemsHelper.fetchDownSystemsData(
        rowsPerPage,
        page * rowsPerPage,
        order,
        orderBy,
        sonarFilter,
        setIsLoading,
        abortController
      )

      const digestedDownSystemData = downSystemsHelper.digestDownSystemsData(
        results
      )
      setTableCount(count)
      setDownSystemsData(digestedDownSystemData)
      setLastRefreshed(downSystemsHelper.formatTime(new Date()))
    },
    [order, orderBy, page, rowsPerPage, sonarFilter]
  )

  const retrieveDownSystemsDataDownload = useCallback(
    async abortController => {
      const fileName = downSystemsHelper.defineDownSystemsDataFileName(
        sonarFilter
      )
      setCsvFilename(fileName)

      const { results } = await downSystemsHelper.fetchDownSystemsData(
        tableCount,
        0,
        order,
        orderBy,
        sonarFilter,
        setIsLoading,
        abortController
      )

      // Set the isSnoozed property based on the snoozeId and remove the snoozeId from the download data
      const updatedResults = results.map((result: ISonarDownSystem) => {
        const { snoozeId, ...rest } = result
        return {
          ...rest,
          snoozed: String(snoozeId !== null),
        }
      })
      setDownSystemsDownloadData(updatedResults)
    },
    [order, orderBy, sonarFilter, tableCount]
  )

  const handleTableColumnSort = useCallback(
    (_event: React.MouseEvent<HTMLSpanElement, MouseEvent>, column: string) => {
      // Handle the sorting logic
      const isAsc = orderBy === column && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(column)
    },
    [order, orderBy]
  )

  const onChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage)
    },
    []
  )

  const onChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value))
    },
    []
  )

  useEffect(() => {
    const abortController = new AbortController()
    const interval = setInterval(() => {
      retrieveDownSystemsData(abortController)
    }, REFRESH_INTERVAL_DOWNSYSTEMS)

    return () => {
      abortController.abort()
      clearInterval(interval)
    }
  }, [retrieveDownSystemsData])

  useEffect(() => {
    const abortController = new AbortController()
    retrieveDownSystemsData(abortController)

    return () => {
      abortController.abort()
    }
  }, [retrieveDownSystemsData, snoozeSavedFlag])

  useEffect(() => {
    const abortController = new AbortController()
    retrieveDownSystemsDataDownload(abortController)

    return () => {
      abortController.abort()
    }
  }, [retrieveDownSystemsDataDownload])

  return {
    csvFilename,
    downSystemsData,
    downSystemsDownloadData,
    handleTableColumnSort,
    lastRefreshed,
    onChangePage,
    onChangeRowsPerPage,
    order,
    orderBy,
    page,
    rowsPerPage,
    tableCount,
    setSnoozeSavedFlag,
    isLoading,
  }
}

const hook = {
  useDownSystems,
}

export default hook
